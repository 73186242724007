import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'
import styled from '@emotion/styled'

const Text = styled.div`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: 0 auto 20px;
`

const Contact = ({ data }) => {
  return (
    <Layout>
      <SEO title="Kontakt" description="Kontakta AN Takplat" />
      <Container>
        <PageTitle>Kontakt</PageTitle>
        <Text>
          Vi kan lämna offert och quis eiusmod in nisi do. Enim tempor id in
          nulla id. Cupidatat esse velit excepteur aliqua ipsum in adipisicing
          eiusmod mollit aute excepteur occaecat eiusmod non. Laborum dolore ut
          laborum fugiat occaecat aute aute consequat aute magna id ad ex est.
        </Text>
        <ContactForm />
      </Container>
    </Layout>
  )
}

export default Contact
